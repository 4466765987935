import * as GQL from "../lib/interface_gql";

import MainUi from "../components/MainUi";

import { Breadcrumb, Button, Form, Input, Layout, Select, notification, theme } from "antd";
import { gql, useMutation } from "@apollo/client";
import { Auth, Errors } from "../lib/util";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function UserAdd() {
  const navigate = useNavigate();
  useEffect(() => { if (!Auth.isAuthenticated()) navigate("/login"); });
  const [antNotification, contextHolder] = notification.useNotification();
  const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();

  const [createUser, { loading: ld0, error: er0 }] = useMutation<Pick<GQL.Mutation, "AddUser">, GQL.MutationAddUserArgs>(gql`
    mutation CreateUser($user: UserInput!) {
      AddUser(user: $user) {
        accessToken
        validTill
      }
    }
  `);

  return <MainUi>
    {contextHolder}
    <Breadcrumb style={{ margin: "16px 0" }} items={[
      { title: "Home" }, { title: "Users" }, { title: "Add User" }
    ]} />

    <Layout.Content style={{
      padding: 24,
      background: colorBgContainer,
      borderRadius: borderRadiusLG,
    }}>
      <div style={{ width: 600 }}>
        <Form
          layout="vertical"
          name="User-register-form"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 16 }}
          // style={{ maxWidth: 600 }}
          onFinish={(formData: GQL.MutationAddUserArgs) => {
            createUser({
              variables: formData
            }).then((res) => {
              if (res.data) antNotification.open({ message: "user created successfully", });
            }).catch(error => console.error(error.message));
            if (er0) Errors.gql(er0, antNotification);
          }}
        >
          <Form.Item<GQL.MutationAddUserArgs>
            name={["user", "email"]}
            label="E-mail"
            // noStyle
            rules={[{ required: true, message: "Email is required" }]}
          >
            <Input placeholder="example@gmail.com" />
          </Form.Item>

          <Form.Item<GQL.MutationAddUserArgs>
            label="Nickname"
            name={["user", "preferredName"]}
            rules={[{ required: true, message: "Please input your nick name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<GQL.MutationAddUserArgs>
            label="Password"
            name={["user", "password"]}
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item<GQL.MutationAddUserArgs>
            name={["user", "roleId"]}
            label="User type"
            rules={[{ required: true, message: "Please select a user!" }]}
          >
            <Select>
              <Select.Option value="100000000000000000000001">Standard</Select.Option>
              <Select.Option value="100000000000000000000000">Admin</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item colon={false}>
            <Button type="primary" htmlType="submit" loading={ld0}>
              Register
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout.Content>
  </MainUi >;
};