import { useMutation } from "@apollo/client";
import { Button, Cascader, Col, Form, FormInstance, Input, InputNumber, Row, Switch } from "antd";
import { Data, Errors } from "../../lib/util";
import { NotificationInstance } from "antd/es/notification/interface";
import { Category } from "../../pages/Products";
import { gql } from "../../lib/interface";

export type ProductFormType = {
  isActive: boolean;
  brand: string;
  categoryId: [string, string, string];
  code: string;
  description: string;
  name: string;
  price0: string;
  price1: string;
  price2: string;
  price01Qty: number;
  price12Qty: number;
  location: string | null;
  quantity: number;
};

export default function ProductForm({
  form,
  categories,
  productId,
  antNotification,
  onFinish
}: {
  form: FormInstance<ProductFormType>
  categories: Category[]
  productId: string | null
  antNotification: NotificationInstance
  onFinish: (arg0: string | boolean) => void
}) {
  const [updateProduct, { loading: ld1, error: err1 }] = useMutation(gql(`
    mutation UpdateProduct($productId: ObjectId!, $product: ProductInput!) {
      UpdateProduct(productId: $productId, product: $product)
    }
  `));
  const [addProduct, { loading: ld2, error: err2 }] = useMutation(gql(`
    mutation AddProduct($product: ProductInput!) {
      AddProduct(product: $product)
    }
  `));

  if (err1) Errors.gql(err1, antNotification);
  else if (err2) Errors.gql(err2, antNotification);

  return <Form layout="vertical" form={form} onFinish={formData => {
    if (productId) {
      updateProduct({
        variables: {
          productId,
          product: {
            ...formData,
            categoryId: formData.categoryId[2],
            price0: Number.parseFloat(formData.price0),
            price1: Number.parseFloat(formData.price1),
            price2: Number.parseFloat(formData.price2)
          }
        }
      }).then(res => {
        if (res.data?.UpdateProduct) onFinish(res.data.UpdateProduct);
      }).catch(error => console.error(error.message));
    } else {
      addProduct({
        variables: {
          product: {
            ...formData,
            categoryId: formData.categoryId[2],
            price0: Number.parseFloat(formData.price0),
            price1: Number.parseFloat(formData.price1),
            price2: Number.parseFloat(formData.price2)
          }
        }
      }).then(res => {
        if (res.data?.AddProduct) onFinish(res.data.AddProduct);
      }).catch(error => console.error(error.message));
    }
  }}>
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Form.Item label="Active" name="isActive" >
          <Switch />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Form.Item label="Product code" name="code" rules={[
          { required: true, message: "" }
        ]} >
          <Input size="large" placeholder="eg: PRO1998" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Quantity" name="quantity" rules={[
          { required: true, message: "" },
          { pattern: /^\d{1,5}$/, message: "eg: 10000" }
        ]} >
          <InputNumber size="large" type="number" placeholder="eg: 10000" min={0} style={{ width: "100%" }} />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      <Col span={8} >
        <Form.Item label="Price tier 1" name="price0" rules={[
          { required: true, message: "" },
          { pattern: /^\d{1,5}[.]\d{2}$/, message: "eg: 1050.00" }
        ]} >
          <Input size="large" placeholder="eg: 1050.00" />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Price tier 2" name="price1" rules={[
          { required: true, message: "" },
          { pattern: /^\d{1,5}[.]\d{2}$/, message: "eg: 1050.00" }
        ]} >
          <Input size="large" placeholder="eg: 1050.00" />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Price tier 3" name="price2" rules={[
          { required: true, message: "" },
          { pattern: /^\d{1,5}[.]\d{2}$/, message: "eg: 1050.00" }
        ]} >
          <Input size="large" placeholder="eg: 1050.00" />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Form.Item label="Quantity tier 1" name="price01Qty" rules={[{ required: true, message: "" }]} >
          <InputNumber size="large" type="number" placeholder="eg: 10" min={0} style={{ width: "100%" }} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Quantity tier 2" name="price12Qty" rules={[
          { required: true, message: "" },
          {
            validator: async (rule, value) => {
              if (value <= form.getFieldValue("price01Qty")) {
                return Promise.reject("Tier 2 quantity must be greater than tier 1 quantity");
              }
              return Promise.resolve();
            }
          }
        ]} >
          <InputNumber size="large" type="number" placeholder="eg: 10" min={1} style={{ width: "100%" }} />
        </Form.Item>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <Form.Item label="Category" name="categoryId" rules={[{ required: true, message: "" }]} >
          <Cascader size="large" placeholder="Select a category" options={Data.generateCascaderCategories(categories)} />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <Form.Item label="Brand name" name="brand" rules={[
          { required: true, message: "" },
          { pattern: /^[a-zA-Z0-9\s]{3,20}$/, message: "Alphanumeric characters only. 3-20 characters" }
        ]} >
          <Input size="large" placeholder="eg: Sony" />
        </Form.Item>
      </Col>
      <Col span={16}>
        <Form.Item label="Product name" name="name" rules={[
          { required: true, message: "" },
          { pattern: /^[a-zA-Z0-9\s]{3,20}$/, message: "Alphanumeric characters only. 3-20 characters" }
        ]} >
          <Input size="large" placeholder="eg: W60D LED TV" />
        </Form.Item>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <Form.Item label="Location" name="location" >
          <Input size="large" placeholder="eg: AAA-12a-000" />
        </Form.Item>
      </Col>

    </Row>
    <Row justify="space-evenly">
      <Col span={24}>
        <Form.Item label="Description" name="description" rules={[{ required: true, message: "" }]} >
          <Input.TextArea maxLength={500} showCount rows={4} size="large" />
        </Form.Item>
      </Col>
    </Row>
    <Form.Item>{productId ?
      <Button size="large" type="primary" htmlType="submit" loading={ld1}>Update</Button> :
      <Button size="large" type="primary" htmlType="submit" loading={ld2}>Next</Button>
    }</Form.Item>
  </Form>;
}