import * as GQL from "../lib/interface_gql";
import * as Config from "../config";
import { Row, Col, Breadcrumb, Button, theme, AutoComplete, notification, Form, Layout } from 'antd';
import MainUi from "../components/MainUi";
import { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Auth, Errors } from "../lib/util";
import { useNavigate } from "react-router-dom";
import CategoryTree from "../components/categories/CategoryTree";


export default function CategoryAdd() {
  console.log("render CategoryAdd");

  const navigate = useNavigate();
  useEffect(() => { if (!Auth.isAuthenticated()) navigate("/login"); });
  const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();
  const [antNotification, contextHolder] = notification.useNotification();

  const [addCategory, { error: err2 }] = useMutation<Pick<GQL.Mutation, "AddCategory">, GQL.MutationAddCategoryArgs>(gql`
    mutation AddCategory($category: CategoryInput!) {
      AddCategory(category: $category)
    }`);

  const [parsedCategories, setParsedCategories] = useState<null | [Set<string>, Set<string>, Set<string>]>(null);
  async function fetchData() {
    const allCategories: GQL.Category[] = [];
    let skip = 0;
    while (true) {
      const res: any = await fetch(Config.URL_API_GQL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": sessionStorage.getItem("accessToken")!
        },
        body: JSON.stringify({
          query: `query GetCategories($skip: Int) {
            GetCategories(skip: $skip) {
              path
              _id
              isActive
            }
          }`,
          variables: {
            skip
          }
        }),
      }).then(res => res.json());
      const categories = res.data.GetCategories;

      if (categories) {
        allCategories.push(...categories);
        if (categories.length === 10) {
          skip += 10;
        } else {
          break;
        }
      }
    }
    //[[men, clo, shirt], [women, clothing, fitness], ...]
    const splitCategoryComponents = allCategories.map(category => {
      return category.path.split("/");
    });

    const categoriesTier0 = new Set<string>();
    const categoriesTier1 = new Set<string>();
    const categoriesTier2 = new Set<string>();
    for (let i = 0; i < splitCategoryComponents.length; i++) {
      categoriesTier0.add(splitCategoryComponents[i][0]);
      categoriesTier1.add(splitCategoryComponents[i][1]);
      categoriesTier2.add(splitCategoryComponents[i][2]);
    }
    setParsedCategories([categoriesTier0, categoriesTier1, categoriesTier2]);
  }
  useEffect(() => {
    if (parsedCategories === null) fetchData();
  });

  if (err2) Errors.gql(err2, antNotification);

  return <MainUi>
    {contextHolder}

    <Breadcrumb style={{ margin: "16px 0" }} items={[
      { title: "Home" }, { title: "Categories" }, { title: "Add Category" }
    ]}>
    </Breadcrumb>

    <Layout.Content style={{ padding:24, background: colorBgContainer, borderRadius: borderRadiusLG }}>
      <Row gutter={[16,16]}>
        <Col span={8} >
          {parsedCategories && <>
            <Form layout="vertical" onFinish={FormData => {
              addCategory({
                variables: {
                  category: {
                    "path": FormData.tier1 + "/" + FormData.tier2 + "/" + FormData.tier3
                  } as GQL.CategoryInput
                }
              }).then(res => {
                if (res.data?.AddCategory) {
                  antNotification.success({
                    message: "Category added successfully"
                  });
                }
              }).catch(error => console.error(error.message));
              console.log(FormData.tier1 + "/" + FormData.tier2 + "/" + FormData.tier3);
            }}>
              <Row gutter={[16, 16]} >
                <Col span={15} >
                  <Form.Item
                    name="tier1"
                    label="Category tier 1"
                    rules={[
                      { required: true, message: "Please select a category" },
                      { pattern: /^[a-zA-Z\s]{3,}$/, message: "Invalid input, must be at least 3 characters long and contain only alphabets and spaces" }
                    ]}
                  >
                    <AutoComplete
                      size='large'
                      options={Array.from(parsedCategories[0]).map(category => {
                        return {
                          value: category
                        }
                      })}
                      allowClear={true}

                      notFoundContent="No data found"
                      placeholder="type something to search"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={15}>
                  <Form.Item
                    name="tier2"
                    label="Category tier 2"
                    rules={[
                      { required: true, message: "Please select a category" },
                      { pattern: /^[a-zA-Z\s]{3,}$/, message: "Invalid input, must be at least 3 characters long and contain only alphabets and spaces" }
                    ]}
                  >
                    <AutoComplete
                      size='large'
                      options={Array.from(parsedCategories[1]).map(category => {
                        return {
                          value: category
                        }
                      })}
                      allowClear={true}

                      notFoundContent="No data found"
                      placeholder="type something to search"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={15}>
                  <Form.Item
                    name="tier3"
                    label="Category tier 3"
                    rules={[
                      { required: true, message: "Please select a category" },
                      { pattern: /^[a-zA-Z\s]{3,}$/, message: "Invalid input, must be at least 3 characters long and contain only alphabets and spaces" }
                    ]}
                  >
                    <AutoComplete
                      size='large'
                      options={Array.from(parsedCategories[2]).map(category => {
                        return {
                          value: category
                        }
                      })}
                      allowClear={true}

                      notFoundContent="No data found"
                      placeholder="type something to search"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={15}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </>}
        </Col>
        <Col span={5} >
          <CategoryTree />
        </Col>
      </Row>
    </Layout.Content>
  </MainUi>
}