import * as GQL from "../lib/interface_gql";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Form, Input, Layout, Typography, notification } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Errors } from "../lib/util";
import { gql, useMutation } from "@apollo/client";

export default function Login() {
  const navigate = useNavigate();
  useEffect(() => { if (data) navigate("/dashboard"); });
  const [antNotification, contextHolder] = notification.useNotification();

  const [form] = Form.useForm();
  const [signIn, { loading, error, data }] = useMutation<Pick<GQL.Mutation, "SignIn">, GQL.MutationSignInArgs>(gql`
    mutation SignIn($email: String!, $password: String!) {
      SignIn(email: $email, password: $password) {
        accessToken validTill
      }
    }
  `);

  if (error) Errors.gql(error, antNotification);

  return <Layout style={{ height: "100vh" }}>
    {contextHolder}
    <Card
      bodyStyle={{ padding: "50px" }}
      style={{
        width: "400px",
        margin: "100px auto",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
      }}
    >
      <Typography.Title
        level={2}
        style={{ marginBottom: "30px", textAlign: "center" }}
      >
        Welcome!
      </Typography.Title>
      <Form form={form} onFinish={(values: GQL.MutationSignInArgs) => {
        signIn({ variables: values }).then(res => {
          if (res.data) {
            sessionStorage.setItem("accessToken", res.data.SignIn.accessToken);
            sessionStorage.setItem("validTill", res.data.SignIn.validTill.toString());
          };
        }).catch(error => console.error(error.message));
      }}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "" },
            { pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            , message: "" }
          ]}
        >
          <Input size="large" prefix={<MailOutlined />} placeholder="eg: abc@gmail.com" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "" }]}
        >
          <Input.Password size="large" prefix={<LockOutlined />} placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: "100%" }}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </Card>
  </Layout>;
};
