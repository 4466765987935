import ProductForm, { ProductFormType } from "./ProductForm";
import { Drawer, Form } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";
import { Category, Product } from "../../pages/Products";

export default function ProductDrawer({
  antNotification,
  categories,
  selectedItem,
  setSelectedItem,
  onFinish
}: {
  antNotification: NotificationInstance;
  categories: Category[];
  selectedItem: Product | null;
  setSelectedItem: React.Dispatch<React.SetStateAction<Product | null>>;
  onFinish: (arg0: boolean | string) => void;
}) {
  const [form] = Form.useForm<ProductFormType>();

  if (selectedItem) {
    const categoryPathComponents = selectedItem.category.path.split("/");
    form.setFieldsValue({
      ...selectedItem,
      categoryId: [categoryPathComponents[0], categoryPathComponents[1], selectedItem.category._id],
      price0: selectedItem.price0.toFixed(2),
      price1: selectedItem.price1.toFixed(2),
      price2: selectedItem.price2.toFixed(2)
    });
  }

  return <Drawer width={600} open={selectedItem !== null} onClose={() => setSelectedItem(null)} styles={{ body: { paddingBottom: 80 } }} >
    <ProductForm form={form} categories={categories} productId={selectedItem?._id ?? null} antNotification={antNotification} onFinish={onFinish} />
  </Drawer>;
}