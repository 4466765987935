import * as GQL from "../../lib/interface_gql";
import { Data } from "../../lib/util";
import { gql, useQuery } from '@apollo/client';
import { Tree } from 'antd';  //type TreeDataNode
import React from "react";
import { useEffect } from "react";

type TreeDataNode = {
    title: string;
    key: string;
    children?: TreeDataNode[];
};

export default function CategoryTree() {
    const { error: err0, data, fetchMore } = useQuery<Pick<GQL.Query, "GetCategories">, GQL.QueryGetCategoriesArgs>(gql`
        query Query($skip: Int) {
            GetCategories(skip: $skip) {
            _id
            isActive
            path
            }
        }   
    `);

    useEffect(() => {
        if (data) {
            fetchMore({
                variables: {
                    skip: data.GetCategories.length,
                },
            })
        }
    }
    )

    const dataObject: Record<string, Record<string, Record<string, string>>> = Data.parseCategories(data?.GetCategories || []);

    function convertToTreeData(input: any, path: string = ''): TreeDataNode[] {
        return Object.keys(input).map((key) => {
            const newPath = path ? `${path}-${key}` : key;
            if (typeof input[key] === 'object') {
                return {
                    title: key,
                    key: newPath,
                    children: convertToTreeData(input[key], newPath),
                };
            } else {
                return {
                    title: key,
                    key: newPath,
                };
            }
        });
    }
    const treeData: TreeDataNode[] = convertToTreeData(dataObject);

    const onSelect = (selectedKeys: React.Key[], info: any) => {
        console.log('selected', selectedKeys, info);
    };
    if (err0) {
        console.log(err0);
    }
    return (
        <Tree
            style={{ margin: 15 }}
            height={500}
            showLine={true}
            showIcon={false}
            defaultExpandedKeys={['0-0-0']}
            onSelect={onSelect}
            treeData={treeData}
        />
    )
}