import * as Config from "../../config";
import ProductForm, { ProductFormType } from "./ProductForm";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Space, Steps, Typography, Upload, UploadFile } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";
import { RcFile } from "antd/es/upload";
import { useState } from "react";
import { Category } from "../../pages/Products";

const steps = [
  { key: "0", title: "Product Details" },
  { key: "1", title: "Product Images" }
];

export default function ProductModal({
  open,
  setOpen,
  categories,
  antNotification
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  categories: Category[];
  antNotification: NotificationInstance;
}) {
  const [form] = Form.useForm<ProductFormType>();
  const [newProductId, setNewProductId] = useState<string | null>(null);
  const [uploadStatus, setUploadStatus] = useState([false, false, false, false, false]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  return <Modal title="Add Product" width={620} centered open={open} onCancel={() => setOpen(false)} footer={null} >
    <Steps style={{ margin: "24px 0" }} current={newProductId ? 1 : 0} items={steps} />

    {!newProductId && <ProductForm form={form} categories={categories} productId={null} antNotification={antNotification} onFinish={(productId) => {
      antNotification.success({ message: "Product added successfully" })
      setNewProductId(productId as string);
    }} />}

    {newProductId && <>
      <Space direction="vertical">
        <Typography.Text>&bull; Images must be of type .webp or .jpeg or .jpg or .png</Typography.Text>
        <Typography.Text>&bull; Images must be less than 50 KB</Typography.Text>
        <Typography.Text>&bull; Once you add an image, it will be uploaded immediately</Typography.Text>
      </Space>
      <Space style={{ margin: "24px 0" }} direction="horizontal">
        {["img1", "img2", "img3", "img4", "img5"].map((item, index) => <Upload
          name={item} maxCount={1} accept=".webp,.jpg,.jpeg,.png" listType="picture-card"
          action={`${Config.URL_API_UPLOADS_IMG}/products/${newProductId}`}
          headers={{ "Authorization": sessionStorage.getItem("accessToken") as string }}
          onRemove={() => {
            const uploadStatusClone = uploadStatus.slice();
            uploadStatusClone[index] = false;
            setUploadStatus(uploadStatusClone);
          }}
          onPreview={async (file: UploadFile) => {
            if (!file.url && !file.preview) {
              //CASE: Image has no url or base64 encoded string
              //Create the preview field with base64 image
              file.preview = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = (error) => reject(error);
              });
            }

            setPreviewImage(file.url ?? (file.preview as string));
            setPreviewOpen(true);
          }}
          beforeUpload={(file: RcFile) => {
            const isCorrectMime = file.type === "image/webp" || file.type === "image/jpeg" || file.type === "image/png";
            if (!isCorrectMime) antNotification.error({
              message: "Only WebP/JPEG/JPG/PNG images are accepted"
            });

            const isProperSize = file.size <= 50 * 1024 //50 KB;
            if (!isProperSize) antNotification.error({
              message: "Please keep the file size under 50 KB"
            });

            const doUpload = isCorrectMime && isProperSize;
            if (doUpload) {
              const uploadStatusClone = uploadStatus.slice();
              uploadStatusClone[index] = true;
              setUploadStatus(uploadStatusClone);
            }

            return doUpload || Upload.LIST_IGNORE;
          }}
        >{uploadStatus[index] === false && <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Image {index + 1}</div>
        </div>}</Upload>)}
      </Space>
      <Button size="large" type="primary" htmlType="submit" onClick={() => {
        setNewProductId(null);
        setUploadStatus([false, false, false, false, false]);
        form.resetFields();
        setOpen(false);
      }} >Done</Button>

      <Modal open={previewOpen} footer={null} centered onCancel={() => setPreviewOpen(false)}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>}
  </Modal>
}