import { Button, Layout, Result } from "antd";
import MainUi from "../components/MainUi";
import { useNavigate } from "react-router-dom";

export default function UserView() {
  const navigate = useNavigate();

  return <MainUi>
    <Layout.Content style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary" onClick={() => { navigate("/dashboard") }}>Back Home</Button>}
      />
    </Layout.Content>
  </MainUi>
};
