import type { introspection } from "./graphql-env.js";
import { TadaDocumentNode, initGraphQLTada } from "gql.tada";

export const gql = initGraphQLTada<{
  introspection: typeof introspection,
  scalars: {
    ObjectId: string,
    Date: number
  }
}>();

export type Result<Doc extends TadaDocumentNode> = Doc extends TadaDocumentNode<infer R> ? R : never;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type Variables<Doc extends TadaDocumentNode> = Doc extends TadaDocumentNode<infer R, infer V> ? V : never;

export interface CascaderOption {
  value: string;
  label: string;
  children?: CascaderOption[];
}