import * as Icon from "@ant-design/icons";

import { Image, Layout, Menu, MenuProps } from "antd";
import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

function createMenuItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return { key, icon, children, label } as MenuItem;
}

export default function MainUi(props: {
  children: ReactNode
}) {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  return <Layout style={{ height: "100vh" }}>
    <Layout.Sider collapsible collapsed={collapsed} width={250} onCollapse={(value) => setCollapsed(value)}>
      <Image src="/logo192.png" width="100%" preview={false} style={{
        margin: "10px 0",
        objectFit: "scale-down"
      }} />
      <Menu
        theme="dark"
        mode="vertical"
        style={{
          padding: "0 20px",
          display: "flex",
          flexDirection: "column",
          gap: 30
        }}
        onClick={({ key }) => { navigate(key) }}
        items={[
          createMenuItem("Roles", "1", <Icon.TeamOutlined />, [
            createMenuItem("View Roles", "/roles"),
            createMenuItem("Add Role", "/roles/@new")
          ]),
          createMenuItem("Users", "2", <Icon.UserOutlined />, [
            createMenuItem("View Users", "/users"),
            createMenuItem("Add User", "/users/@new")
          ]),
          createMenuItem("Categories", "3", <Icon.AppstoreOutlined />, [
            createMenuItem("View Categories", "/categories"),
            createMenuItem("Add Category", "/categories/@new")
          ]),
          createMenuItem("Products", "/products", <Icon.ApiOutlined />)
        ]}
      />
    </Layout.Sider>
    <Layout style={{ padding: "0 24px 24px 24px", overflow: "initial" }}>
      {props.children}
    </Layout>
  </Layout>
}