import * as GQL from "./interface_gql";
import { ApolloError } from "@apollo/client";
import { NotificationInstance } from "antd/es/notification/interface";
import { CascaderOption } from "./interface";

export class Auth {
  static isAuthenticated() {
    const validTill = sessionStorage.getItem("validTill");
    if (validTill) {
      //CASE: A token exists
      if (Date.now() > parseInt(validTill)) {
        //CASE: Token expired
        return false;
      } else {
        //CASE: Token is valid
        return true;
      }
    } else {
      return false;
    }
  }
}

export class Errors {
  static gql(error: ApolloError, antNotification: NotificationInstance) {
    //CASE: Error in GenerateOTP
    if (error.graphQLErrors.length > 0) {
      //CASE: A GraphQL error is present
      antNotification.error({
        message: error.message,
        description: error.graphQLErrors[0].extensions.suggestion ? error.graphQLErrors[0].extensions.suggestion as string : ""
      });
    } else if (error.networkError) {
      if ("result" in error.networkError) {
        //CASE: error is of type ServerError
        antNotification.error({
          message: error.message,
          //@ts-ignore
          description: error.networkError.result.errors[0].message as string
        });
      } else if ("bodyText" in error.networkError) {
        //CASE: error is of type ServerParseError
        antNotification.error({
          message: error.message,
          description: error.networkError.bodyText as string
        });
      } else {
        antNotification.error({
          message: "Network request failed",
          description: "Please check your network connection"
        });
      }
    } else {
      antNotification.error({
        message: error.message
      });
    }
  }
}

export class Data {
  static parseCategories(categories: GQL.Query["GetCategories"]) {
    const processedCategories: Record<string, Record<string, Record<string, string>>> = {};

    for (const category of categories) {
      if (!category.isActive) {
        continue;
      };
      
      const [tier0Category, tier1Category, tier2Category] = category.path.split("/") as [string, string, string];

      if (!processedCategories[tier0Category]) {
        processedCategories[tier0Category] = {};
      }

      if (!processedCategories[tier0Category]?.[tier1Category]) {
        processedCategories[tier0Category][tier1Category] = {};
      }

      processedCategories[tier0Category][tier1Category][tier2Category] = category._id;
    }

    return processedCategories;
  }

  static generateCascaderCategories(categories: GQL.Query["GetCategories"] | undefined) {
    if (categories === undefined) {
      return [];
    }

    const parsedCategories = this.parseCategories(categories);
    const cascaderOptions: CascaderOption[] = Object.keys(parsedCategories).map(tier0Category => {
      return {
        value: tier0Category,
        label: tier0Category,
        children: Object.keys(parsedCategories[tier0Category]).map(tier1Category => {
          return {
            value: tier1Category,
            label: tier1Category,
            children: Object.keys(parsedCategories[tier0Category][tier1Category]).map(tier2Category => {
              return {
                value: parsedCategories[tier0Category][tier1Category][tier2Category],
                label: tier2Category
              }
            })
          }
        })
      }
    });

    return cascaderOptions;
  }
}