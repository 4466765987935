import "./index.css";

import * as Config from "./config";

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/LogIn";
import Products from "./pages/Products";
import UserAdd from "./pages/UserAdd";
import reportWebVitals from "./reportWebVitals";
import CategoryAdd from "./pages/CategoryAdd";
import UserView from "./pages/UserView";

import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from "@apollo/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { setContext } from "@apollo/client/link/context";
import { ConfigProvider, theme } from "antd";

function merge(existing: any[] = [], incoming: any[]) {
  if (existing.length > 0) {
    const merged = [...existing];

    // Add incoming items that don't exist in the existing array
    incoming.forEach(incomingItem => {
      const foundIncomingItemWithinExisting = existing.some(existingItem => existingItem.__ref === incomingItem.__ref);
      if (!foundIncomingItemWithinExisting) {
        merged.push(incomingItem);
      }
    });

    return merged;
  } else {
    return incoming;
  }
}

const apollo = new ApolloClient({
  link: setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem("accessToken")
      },
    }
  }).concat(createHttpLink({
    uri: Config.URL_API_GQL,
  })),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          GetProducts: {
            keyArgs: ["keyword", "categoryId"], // Don't merge when these fields are changed
            merge,
          },
          GetCategories: {
            keyArgs: ["keyword"], // Don't merge when these fields are changed
            merge,
          }
        }
      }
    }
    
  })
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/users/@new",
    element: <UserAdd />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/users",
    element: <UserView />,
  },
  {
    path: "/categories",
    element: <UserView />,
  },
  {
    path: "/categories/@new",
    element: <CategoryAdd />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ApolloProvider client={apollo}>
      <ConfigProvider theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#a3131e",
          colorInfo: "#a3131e"
        }
      }}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </ApolloProvider>
  </React.StrictMode>
);

reportWebVitals();
