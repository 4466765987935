import MainUi from "../components/MainUi";
import ProductDrawer from "../components/products/ProductDrawer";
import ProductModal from "../components/products/ProductModal";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Breadcrumb, Button, Cascader, Flex, Input, InputRef, Layout, Space, Table, notification, theme } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, Data, Errors } from "../lib/util";
import { ColumnType, ColumnsType, FilterConfirmProps } from "antd/es/table/interface";
import { Result, gql } from "../lib/interface";

const query = {
  getCategories: gql(`
    query GetCategories($skip: Int! = 0) {
      GetCategories(skip: $skip) {
        _id path isActive
      }
    }
  `),

  getProducts: gql(`
    query GetProducts($skip: Int, $limit: Int, $categoryId: ObjectId, $keyword: String) {
      GetProducts(skip: $skip, limit: $limit, categoryId: $categoryId, keyword: $keyword) {
        _id isActive code name brand price0 price1 price2 description location price01Qty price12Qty quantity category { _id path } 
      }
    }
  `)
};

export type Product = Result<typeof query.getProducts>["GetProducts"][number];
export type Category = Result<typeof query.getCategories>["GetCategories"][number];

export default function Products() {
  const navigate = useNavigate();
  useEffect(() => { if (!Auth.isAuthenticated()) navigate("/login"); });
  const [antNotification, contextHolder] = notification.useNotification();
  const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();

  const [hasMoreCategories, setHasMoreCategories] = useState(true);
  const { loading: ld0, error: err0, data: dt0, fetchMore: fm0 } = useQuery(query.getCategories);

  useEffect(() => {
    if (dt0 && hasMoreCategories) {
      fm0({
        variables: { skip: dt0.GetCategories.length }
      }).then(res => {
        if (res.data.GetCategories.length === 10) {
          setHasMoreCategories(true);
        } else {
          setHasMoreCategories(false);
        }
      });
    }
  });

  const [categoryId, setCategoryId] = useState<string | undefined>(undefined);
  const [keyword, setKeyword] = useState<string>("");
  const { loading: ld1, error: err1, data: dt1, fetchMore: fm1, refetch: rf1 } = useQuery(query.getProducts, {
    variables: { categoryId, keyword }
  });
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const searchInput = useRef<InputRef>(null);

  if (err0) Errors.gql(err0, antNotification);
  if (err1) Errors.gql(err1, antNotification);

  const handleSearch = (selectedKeys: React.Key[], confirm: (param?: FilterConfirmProps) => void, dataIndex: keyof Product) => confirm();

  const handleReset = (clearFilters: () => void) => clearFilters();

  const getColumnSearchProps = (dataIndex: keyof Product): ColumnType<Product> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => <div style={{ padding: 10 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input ref={searchInput} placeholder={`Search ${dataIndex}`} value={selectedKeys[0]} style={{ marginBottom: 8, display: "block" }}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
      />
      <Space>
        <Button type="primary" icon={<SearchOutlined />} size="small" style={{ width: 90 }}
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
        >Search</Button>
        {/* TODO: Reset only clears the search box */}
        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters && handleReset(clearFilters)} >Reset</Button>
      </Space>
    </div>,

    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,

    onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes((value as string).toLowerCase())?? false,

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  });

  const columns: ColumnsType<Product> = [
    {
      title: "Code",
      dataIndex: "code" satisfies keyof Product,
      key: "code",
      ...getColumnSearchProps("code"),
    },
    {
      title: "Name",
      dataIndex: "name" satisfies keyof Product,
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Brand",
      dataIndex: "brand" satisfies keyof Product,
      key: "brand",
      ...getColumnSearchProps("brand"),
    },
    {
      title: "Price Tier 1",
      dataIndex: "price0" satisfies keyof Product,
      key: "price0",
      sorter: (a, b) => a.price0 - b.price0,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Price Tier 2",
      dataIndex: "price1" satisfies keyof Product,
      key: "price1",
      sorter: (a, b) => a.price1 - b.price1,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Price Tier 3",
      dataIndex: "price2" satisfies keyof Product,
      key: "price2",
      sorter: (a, b) => a.price2 - b.price2,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Location",
      dataIndex: "location" satisfies keyof Product,
      key: "location",
      ...getColumnSearchProps("location"),
    },
    {
      title: "Quantity Split 1",
      dataIndex: "price01Qty" satisfies keyof Product,
      key: "price01Qty"
    },
    {
      title: "Quantity Split 2",
      dataIndex: "price12Qty" satisfies keyof Product,
      key: "price12Qty"
    },
    {
      title: "Quantity",
      dataIndex: "quantity" satisfies keyof Product,
      key: "quantity"
    },
  ];

  return <MainUi>
    {contextHolder}

    {dt0 && <ProductDrawer categories={dt0.GetCategories} selectedItem={selectedProduct} setSelectedItem={setSelectedProduct} onFinish={() => {
      antNotification.success({ message: "Product updated successfully" });
      rf1();
      setSelectedProduct(null);
    }} antNotification={antNotification} />}

    {dt0 && <ProductModal categories={dt0.GetCategories} open={openAddProduct} setOpen={setOpenAddProduct} antNotification={antNotification} />}

    <Breadcrumb style={{ margin: "16px 0" }} items={[{ title: "Home" }, { title: "Products" }]} />

    <Flex gap={16}>
      <Button size="large" type="primary" icon={<PlusOutlined />} onClick={() => setOpenAddProduct(true)}>Add</Button>

      <Space.Compact style={{ marginBottom: 24, flexGrow: 1 }}>
        <Cascader size="large" loading={ld0} placeholder="Filter by category" style={{ width: "150%" }}
          options={Data.generateCascaderCategories(dt0?.GetCategories)}
          onChange={(value: any) => value ? setCategoryId(value[2]) : setCategoryId(undefined)}
        />
        <Input.Search size="large" placeholder="Filter by keyword" onSearch={value => setKeyword(value)} />
      </Space.Compact>
    </Flex>

    <Layout.Content style={{ overflow: "auto", background: colorBgContainer, borderRadius: borderRadiusLG }}>
      <Table loading={ld1} columns={columns} dataSource={dt1?.GetProducts} scroll={{ y: 495 }} style={{ height: "100%" }}
        pagination={{
          pageSize: 9,
          onChange: (page) => {
            fm1({
              variables: { skip: (page - 1) * 10 }
            })
          }
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              setSelectedProduct(record);
            }
          };
        }} />
    </Layout.Content>
  </MainUi>
}