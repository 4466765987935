import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from './lib/util';

export default function App() {
  const navigate = useNavigate();
  useEffect(() => {
    if (Auth.isAuthenticated()) navigate("/dashboard");
    else navigate("/login");
  });

  return <div></div>;
}
